html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'Quicksand', sans-serif;
  background: #ebf6f3;
  color: #2e2e2e;
  text-align: center;
  font-weight: 500;
  letter-spacing: .02em;
}

p {
  margin-bottom: 16px;
}

body.no-scroll {
  height: 100%;
  overflow: hidden;
}

span {
  word-wrap: normal;
}

section {
  max-width: 800px;
  margin: auto;
}

h1 {
  color: #7d7d7d;
  max-width: 385px;
  margin: 0 auto;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 1.5em;
}
h2 {
  max-width: 385px;
  margin: 0 auto;
}

.accent {
  color: #4c9d82;
}

a {
  text-decoration: none;
  color: #136af0;
}
