.Nav-links {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 32px;
}

.Nav-links a {
  margin: 10px;
}
