.Home-heading-block {
  margin-bottom: 64px;

}
.Home-body {
  margin-bottom: 120px;

}
.Home-contact-button {
    margin: 20px auto;
    padding: 18px;
    border: 1px solid #4c9d82;
    color: #4c9d82;
    border-radius: 21px;
    font-size: 20px;
    width: fit-content;
    display: block;
    margin-top: 48px;
}

.Home-flag {
  width: 100%;
  height: 120px;
  background-color: #cbeeff;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.Home-flag>img {
  object-fit: cover;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.Home-flag>h1 {
  color: rgba(220, 200, 210, 0.7);
  letter-spacing: .1em;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
