.ZoomableImage-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.9);
  display: flex;
}

.ZoomableImage-thumbnail {
  width: 100%;
}

.ZoomableImage-overlay img {
  max-width: 95%;
  max-height: 95%;
  margin: auto;
}
